import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';

import ComprehensiveListings from '@/components/ComprehensiveListings';
import { SEO } from '@/components/SEO';
import { SliderSkeleton } from '@/components/Slider/SliderSkeleton';
import { getDescription } from '@/components/Slider/Tiles/GenericTile';
import { T } from '@/components/T';
import { TileCarousel, TileCarouselItem } from '@/components/TileCarousel';
import { TileContent, TileDescription, TileTitle } from '@/components/ui/tile';
import { Alpha, Echo, Foxtrot } from '@/components/ui/typography/heading';
import { NO_BREAK_SPACE } from '@/constants/space';
import { SettingsContext } from '@/providers/SettingsProvider';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';
import { parseEmoji } from '@/utils/helpers';

import {
  getMusicPageCacheKey,
  getMusicPageData,
  getMusicPageHeadings,
  isPopulatedCarousel,
} from './util';

import type { Greeting } from './types';
import type { Category } from '@/hooks/useComprehensiveListings';
import type {
  Carousel,
  RecentDownloadsCarousel,
  StyleCarousel,
} from '@/types/carousel';
import type { Organization } from 'schema-dts';

interface HomePageProps {
  initialCacheKey?: (string | { [x: string]: boolean | string })[];
  initialData?: {
    carousels: {
      homepage?: StyleCarousel[];
      discover?: Carousel[];
      basedOnRecentDl?: RecentDownloadsCarousel[];
      jumpBackIn?: Carousel[];
    };
  };
  initialHeadingsData?: {
    heading: Greeting;
    subheading: string;
  } | null;
  comprehensiveListings?: Category[];
}

export const MusicPage: React.FC<HomePageProps> = ({
  initialCacheKey,
  initialData,
  initialHeadingsData,
  comprehensiveListings,
}) => {
  const { settings } = useContext(SettingsContext);
  const cacheKey = getMusicPageCacheKey(settings);
  const { locale } = useRouter();

  const { data } = useQuery({
    queryKey: cacheKey,
    queryFn: () => getMusicPageData(),
    initialData,
    enabled: !isEqual(cacheKey, initialCacheKey),
  });

  const { data: headingsData } = useQuery({
    queryKey: [settings?.user?.first_name, 'homepage', 'headings'],
    queryFn: () => getMusicPageHeadings(settings),
    staleTime: Infinity, // Maintain the same headings for a session
  });

  const currentHeadingData = useMemo(() => {
    const newHeading = headingsData ?? initialHeadingsData;

    return {
      ...newHeading,
      heading: {
        default: newHeading?.heading?.default
          .replace('{name}', settings?.user?.first_name ?? '')
          .replace('{greeting}', newHeading.heading.timeGreeting ?? ''),
      },
    };
  }, [headingsData, initialHeadingsData, settings?.user?.first_name]);

  useEffect(() => {
    // parseEmoji ends up in a race with Toglee
    if (locale?.startsWith('en')) {
      parseEmoji();
    }
  }, [locale]);

  return (
    <TNamespaceProvider value="homepage">
      <section className="homepage">
        <div className="page-container home-container">
          <SEO
            title="Free Music For YouTube Videos & Creators • Uppbeat"
            description="Download the best copyright-free music for YouTube, TikTok, streaming, social media, podcasts and more. Create a free account & start downloading for free now."
          >
            <script
              {...jsonLdScriptProps<Organization>({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'Uppbeat',
                url: 'https://uppbeat.io/',
                logo: 'https://cdn.uppbeat.io/images/schema-logo.png',
                parentOrganization: {
                  '@type': 'Organization',
                  name: 'Music Vine Limited',
                },
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: 'Tailors Corner, 1 Thirsk Row',
                  addressLocality: 'Leeds',
                  postalCode: 'LS1 4DP',
                  addressCountry: 'UK',
                },
                description:
                  'Uppbeat is a pioneering free music platform for YouTubers and content creators that guarantees no YouTube copyright issues or demonetization of content. Headquartered in Leeds, UK, and owned by Music Vine Limited, Uppbeat launched in 2021 with a curated catalog of top beatmakers and indie artists. The platform now also offers an extensive library of sound effects as well as a leading blog with valuable tips and information for its userbase of over 1.5 million creators.',
                foundingDate: '2021-01-18',
                sameAs: [
                  'https://www.instagram.com/UppbeatOfficial/',
                  'https://www.facebook.com/UppbeatOfficial/',
                  'https://twitter.com/UppbeatOfficial',
                  'https://discord.gg/q5xaCqhGFC',
                ],
              })}
            />
          </SEO>
          <div className="ub-heading ub-heading-home">
            <Alpha>{currentHeadingData?.heading.default}</Alpha>
            <Echo asChild>
              <h2>
                {!!currentHeadingData?.subheading && (
                  <T keyName={currentHeadingData?.subheading} ns="common">
                    {currentHeadingData?.subheading ?? NO_BREAK_SPACE}
                  </T>
                )}
              </h2>
            </Echo>
          </div>
          <div className="home-page_carousel-listing">
            {!data?.carousels ? (
              <>
                <SliderSkeleton />
                <SliderSkeleton />
                <SliderSkeleton />
              </>
            ) : (
              <>
                {data.carousels.discover?.map((carousel) => {
                  if (!isPopulatedCarousel(carousel)) {
                    return null;
                  }

                  return (
                    <React.Fragment key={carousel.name}>
                      <div className="px-container mb-2">
                        <Foxtrot asChild>
                          <h2>{carousel.name}</h2>
                        </Foxtrot>
                      </div>
                      <TileCarousel key={carousel.name} className="mb-6">
                        {carousel.tiles
                          .sort((a, b) => a.order - b.order)
                          .map((tile, index) => (
                            <TileCarouselItem
                              index={index}
                              key={tile.id || tile.url}
                              href={tile.url}
                              src={tile.tile_image}
                              alt={tile.name}
                            >
                              <TileContent>
                                <div>
                                  <TileTitle>{tile.name}</TileTitle>
                                  <TileDescription>
                                    {getDescription(tile)}
                                  </TileDescription>
                                </div>
                              </TileContent>
                            </TileCarouselItem>
                          ))}
                      </TileCarousel>
                    </React.Fragment>
                  );
                })}
                {data.carousels.jumpBackIn?.map((carousel) => {
                  if (!isPopulatedCarousel(carousel)) {
                    return null;
                  }

                  return (
                    <React.Fragment key={carousel.name}>
                      <div className="px-container mb-2">
                        <Foxtrot asChild>
                          <h2>{carousel.name}</h2>
                        </Foxtrot>
                      </div>
                      <TileCarousel key={carousel.name} className="mb-6">
                        {carousel.tiles
                          .sort((a, b) => a.order - b.order)
                          .map((tile, index) => (
                            <TileCarouselItem
                              index={index}
                              key={tile.id || tile.url}
                              href={tile.url}
                              src={tile.tile_image}
                              alt={tile.name}
                            >
                              <TileContent>
                                <div>
                                  <TileTitle>{tile.name}</TileTitle>
                                  <TileDescription>
                                    {getDescription(tile)}
                                  </TileDescription>
                                </div>
                              </TileContent>
                            </TileCarouselItem>
                          ))}
                      </TileCarousel>
                    </React.Fragment>
                  );
                })}
                {data.carousels.basedOnRecentDl?.map((carousel) => {
                  if (!isPopulatedCarousel(carousel)) {
                    return null;
                  }

                  return (
                    <React.Fragment key={carousel.name}>
                      <div className="px-container mb-2">
                        <Foxtrot asChild>
                          <h2>{carousel.name}</h2>
                        </Foxtrot>
                      </div>
                      <TileCarousel key={carousel.name} className="mb-6">
                        {carousel.tiles
                          .sort((a, b) => a.order - b.order)
                          .map((tile, index) => (
                            <TileCarouselItem
                              variant="similar"
                              index={index}
                              key={tile.id || tile.url}
                              href={tile.url}
                              mainImage={tile.tile_image_main}
                              leftImage={tile.tile_image_left}
                              rightImage={tile.tile_image_right}
                              trackName={tile.main_track_name}
                              artistName={tile.main_track_artist}
                              energy={tile.main_track_energy}
                              trackId={tile.main_track_id}
                              alt={tile.name ?? ''}
                            >
                              <TileContent>
                                <TileTitle>{tile.name ?? ''}</TileTitle>
                                <TileDescription />
                              </TileContent>
                            </TileCarouselItem>
                          ))}
                      </TileCarousel>
                    </React.Fragment>
                  );
                })}
                {data.carousels.homepage?.map((carousel) => {
                  if (!isPopulatedCarousel(carousel)) {
                    return null;
                  }

                  return (
                    <React.Fragment key={carousel.category.name}>
                      <div className="px-container mb-2">
                        <Foxtrot asChild>
                          <h2>{carousel.category.name}</h2>
                        </Foxtrot>
                      </div>
                      <TileCarousel
                        key={carousel.category.name}
                        className="mb-6"
                      >
                        {carousel.tiles
                          .sort((a, b) => a.order - b.order)
                          .map((tile, index) => (
                            <TileCarouselItem
                              variant={
                                carousel.category.id === -1
                                  ? 'creator-picks'
                                  : 'default'
                              }
                              index={index}
                              key={tile.id || tile.url}
                              href={tile.url}
                              src={tile.tile_image}
                              alt={tile.name}
                              creatorImage={tile.profile_picture}
                              creatorName={tile.playlistOwnDisplayName}
                            >
                              <TileContent>
                                <div>
                                  <TileTitle>
                                    {carousel.category.id === -1
                                      ? tile.playlistName ?? tile.name
                                      : tile.name}
                                  </TileTitle>
                                  <TileDescription>
                                    {getDescription(tile)}
                                  </TileDescription>
                                </div>
                              </TileContent>
                            </TileCarouselItem>
                          ))}
                      </TileCarousel>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </div>
          <ComprehensiveListings
            variant="music"
            initialData={comprehensiveListings}
          />
        </div>
      </section>
    </TNamespaceProvider>
  );
};
